<template>
  <div class="row">
    <!-- @size-change="handleSizeChange" -->

    <el-pagination @current-change="resetList" :current-page.sync="orderObject.page" layout="slot,prev,pager,next"
      :total="orderObject.count" prev-text="上一页" next-text="下一页" :page-size="orderObject.size"
      :hide-on-single-page="orderObject.count < orderObject.size">
      <button @click="
  orderObject.page = 1;
resetList(1);
      " :class="{ has: orderObject.page == 1 }">
        <span>首页</span>
      </button>
    </el-pagination>
    <el-pagination layout="slot" :hide-on-single-page="orderObject.count < orderObject.size">
      <button @click="
  orderObject.page = Math.ceil(orderObject.count / orderObject.size);
resetList(Math.ceil(orderObject.count / orderObject.size));
      " :class="{
        has:
          orderObject.page == Math.ceil(orderObject.count / orderObject.size),
      }">
        <span>尾页</span>
      </button>
    </el-pagination>
    <el-pagination layout="slot,jumper" :current-page.sync="orderObject.page" :page-size="orderObject.size"
      @current-change="resetList" :total="orderObject.count" :hide-on-single-page="
        orderObject.count < orderObject.size
      ">
      <div class="total-page">
        共 {{ Math.ceil(orderObject.count / orderObject.size) }} 页
      </div>
    </el-pagination>
    <!-- <button @click="resetList( Math.ceil(orderObject.count / orderObject.size))">
      <span>确定</span>
    </button> -->
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    // 相关信息
    orderObject: {
      type: Object,
      default: () => { },
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {
    // orderObject: {
    //   handler(val) {
    //     this.orderObject.size =
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  methods: {
    handleSizeChange() { },
    resetList(e) {
      document.documentElement.scrollTop = 0;
      this.$emit("resetList", e);
    },
  },
  created() { },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
button {
  border: $-solid-border;
  height: 43px;
  line-height: 43px;
  background-color: #fff;

  &:not(.has):hover {
    background-color: $-color-primary;
    border-color: $-color-primary;
    cursor: pointer;

    span {
      color: white;
    }
  }

  &.has {
    cursor: not-allowed;
    color: #c0c4cc;

    &:hover {
      color: #c0c4cc;
    }
  }

  span {
    display: inline-block;
    height: 43px;
    line-height: 43px;
  }
}

/deep/ .el-pagination {
  display: flex;
  align-items: center;
  height: 43px;
  line-height: 43px;
  color: $-color-lighter;
  font-size: 15px !important;
  font-weight: normal;
  padding: 0;

  .btn-prev,
  .btn-next {
    padding: 0 15px;
    margin: 0 7px;

    &:not(:disabled):hover {
      background-color: $-color-primary;
      border-color: $-color-primary;
      cursor: pointer;

      span {
        color: white;
      }
    }
  }

  .el-pager {
    li {
      border: $-solid-border;
      height: 43px;
      line-height: 43px;
      min-width: 40px;

      &:not(.active):hover {
        background-color: $-color-primary;
        border-color: $-color-primary;
        cursor: pointer;
        color: white !important;
      }

      &.active:hover {
        cursor: not-allowed;
      }
    }
  }

  button {
    border: $-solid-border;
    height: 43px;
    line-height: 43px;

    span {
      display: inline-block;
      height: 43px;
      line-height: 43px;
    }
  }

  .total-page {
    margin-left: 20px;
  }

  .el-pagination__jump {
    font-size: 15px !important;
    margin: 0 10px;
    height: 43px;
    line-height: 43px;

    .el-pagination__editor {
      height: 43px;
      line-height: 43px;
      margin: 0 10px;

      .el-input__inner {
        height: inherit;
        border-radius: 0px;
      }
    }
  }
}
</style>