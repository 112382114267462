import axios from 'axios'
import {
    Message
} from 'element-ui'
import router from '../router'
import store from '../store'
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    time: 20 * 1000 // 请求超时时间
})

// 不需要token的路径
const urlWithoutToken = [
    '/main/login'
]

// 特殊的错误码
const specialCodes = ['00302'] //刷新token过期
const comminCodes = [10001]

// request拦截器
service.interceptors.request.use(config => {
    let data = config.data || {};
    config.data = {
        ...data,
        client: 5
    }
    if (store.getters.userInfo && !urlWithoutToken.includes(config.url)) {
        config.headers['token'] = store.getters.userInfo.token
    }

    return config
})

// response拦截器
service.interceptors.response.use(response => {
    const res = response.data
    if (res.code != 1 && !specialCodes.includes(res.code) && !comminCodes.includes(res.code) && !(res instanceof Blob)) {
        Message({
            message: res.msg ? res.msg : res.info || res.desc || res.status || res.status.desc,
            type: 'error',
            duration: 2 * 1000,
            customClass: 'max-index',
            onClose: () => {
                if (res.code == -1) {
                    store.commit("user/SET_USER_INFO", {});
                }
            }
        })

        return res
    } else {
        // if (res.code == 10001) {
        //     router.replace('/certification')
        // }
        return res
    }
}, async error => {
    if (error.response.status === 500) {
        if (error.response.config.url.indexOf('main/login/refresh/') !== -1) {
            return Promise.reject(error)
        } else {
            Message({
                message: error.response.data.message || '请求失败',
                type: 'error',
                duration: 5 * 1000,
                customClass: 'max-index'
            })
        }
        return Promise.reject(error)
    } else {
        Message({
            message: error.response.data.message || '请求失败',
            type: 'error',
            duration: 5 * 1000,
            customClass: 'max-index'
        })
        return Promise.reject(error)
    }
})


export default service