import router from './router'
import NProgress from 'nprogress'
import Router from 'vue-router'
import {
  Message
} from 'element-ui'
import store from '../store'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

// 全局路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta?.title + '-选品商城'
  window.scrollTo(0,0);
  const whiteList = ["/", '/register', '/forgot']
  let isLogin = !!store.getters.userInfo.token; // 登录状态
  let qualificationStatus = store.getters.userInfo.qualification_status; //资质状态
  if (isLogin) {
    NProgress.start();
    if (qualificationStatus == 0 || qualificationStatus == 1 || qualificationStatus == 3) {
      if (to.path != '/certification') {
        Message({
          message: store.state.isAudio ? '正在审核' : '请先填写资质',
          type: 'error',
          duration: 2 * 1000,
          customClass: 'max-index'
        })
        next(false);
        NProgress.done();
      } else {
        next()
      }
    } else {
      if (to.path == '/') {
        next({
          path: "/index",
        })
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({
        path: "/",
        replace: true
      })
    }
  }
});


router.afterEach((to, from) => { //这里不接收next
  NProgress.done();
})

export default router;