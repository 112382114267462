import Vue from "vue";
import Vuex from "vuex";
import user from './module/user'
import getters from './getter';
import Cache from '@/utils/cache'

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    config: Cache.get("config") || {},
    isAudio: false
  },
  modules: {
    user
  },
  mutations: {
    SET_CONFILG: (state, val) => {
      state.config = val
      Cache.set("config", val)
    }
  },
  getters
});