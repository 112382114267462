import request from '@/utils/request';
export default {
    // 添加购物车
    addCartApi: (data) => {
        return request({
            url: '/cart/add',
            method: 'post',
            data
        })
    },
    // 购物车列表
    getCartListApi: (data) => {
        return request({
            url: '/cart/lists',
            method: 'post',
            data
        })
    },
    // 删除购物车
    delCartApi: (data) => {
        return request({
            url: '/cart/del',
            method: 'post',
            data
        })
    },
    // 购物车更改选中状态
    setCartSelectedApi: (data) => {
        return request({
            url: '/cart/selected',
            method: 'post',
            data
        })
    },
    // 购物车更改商品数量
    setCartChangeNumApi: (data) => {
        return request({
            url: '/cart/change',
            method: 'post',
            data
        })
    },
    // 设置结算单
    setTlementApi: (data) => {
        return request({
            url: '/order/settlement',
            method: 'post',
            data
        })
    },
    // 购物车下单
    submitOrderApi: (data) => {
        return request({
            url: '/order/submit_order',
            method: 'post',
            data
        })
    },
    // 获取支付方式
    getPayWayApi: () => {
        return request({
            url: '/order/getPayWay',
            method: 'get',
        })
    },
    // 获取支付方式
    getPayWayApi: () => {
        return request({
            url: '/order/getPayWay',
            method: 'get',
        })
    },
    // 获取商品详情
    getGoodsDetailApi: (params) => {
        return request({
            url: '/goods/getGoodsDetail',
            method: 'get',
            params
        })
    },
    // 收藏/取消收藏
    setSearchRecordApi: (params) => {
        return request({
            url: '/search_record/lists',
            method: 'get',
            params
        })
    },
    // 获取商品评论计数
    getCommentCategoryApi: (params) => {
        return request({
            url: '/goods_comment/category',
            method: 'get',
            params
        })
    },
    // 获取商品评论
    getCommentListApi: (params) => {
        return request({
            url: '/goods_comment/lists',
            method: 'get',
            params
        })
    },
    // 获取产品列表（筛选）
    getGoodsListApi: (params) => {
        return request({
            url: 'goods/getGoodsList',
            method: 'get',
            params
        })
    },
    // 获取热门/精品推荐列表
    getGoodsListByColumnIdApi: (params) => {
        return request({
            url: 'goods/getGoodsListByColumnId',
            method: 'get',
            params
        })
    },
    // 获取订单列表
    getOrderListApi: (params) => {
        return request({
            url: 'order/lists',
            method: 'get',
            params
        })
    },
    // 订单采购经理审核
    setManagerExamineApi: (data) => {
        return request({
            url: 'order/manager_examine',
            method: 'post',
            data
        })
    },
    // 订单财务审核
    setFinanceExamineApi: (data) => {
        return request({
            url: 'order/finance_examine',
            method: 'post',
            data
        })
    },
    // 订单详情
    getOrderDetailApi: (params) => {
        return request({
            url: 'order/getOrderDetail',
            method: 'get',
            params
        })
    },
    // 支付订单
    unifiedpayApi: (data) => {
        return request({
            url: 'pay/unifiedpay',
            method: 'post',
            data
        })
    },
    // 获取订单信息
    getPayStatusApi: (params) => {
        return request({
            url: 'order/getPayStatus',
            method: 'get',
            params
        })
    },
    // 取消订单
    cancelOrderApi: (data) => {
        return request({
            url: 'order/cancel',
            method: 'post',
            data
        })
    },
    // 删除订单
    delOrderApi: (data) => {
        return request({
            url: 'order/del',
            method: 'post',
            data
        })
    },
    // 确认收货
    confirmOrderApi: (data) => {
        return request({
            url: 'order/confirm',
            method: 'post',
            data
        })
    },
    // 查看物流
    getOrderTracesApi: (params) => {
        return request({
            url: 'order/orderTraces',
            method: 'get',
            params
        })
    },
    // 获取评价商品 
    getCommentPageApi: (params) => {
        return request({
            url: 'goods_comment/getCommentPage',
            method: 'get',
            params
        })
    },
    // 添加商品评价
    addGoodsCommentApi: (data) => {
        return request({
            url: 'goods_comment/addGoodsComment',
            method: 'post',
            data
        })
    },
    // 获取评价列表
    getUnCommentOrderApi: (params) => {
        return request({
            url: 'goods_comment/getUnCommentOrder',
            method: 'get',
            params
        })
    },
    // 获取未评价列表
    getCommentOrderApi: (params) => {
        return request({
            url: 'goods_comment/getCommentOrder',
            method: 'get',
            params
        })
    },
    // 获取售后列表
    getAfterSaleListApi: (params) => {
        return request({
            url: 'after_sale/lists',
            method: 'get',
            params
        })
    },
    // 获取售后商品信息
    getAfterSaleGoodsInfoApi: (params) => {
        return request({
            url: 'after_sale/goodsInfo',
            method: 'get',
            params
        })
    },
    // 新增退款
    addAfterSaleApi: (data) => {
        return request({
            url: 'after_sale/add',
            method: 'post',
            data
        })
    },
    // 售后确认收货
    receiveAfterSaleApi: (data) => {
        return request({
            url: 'after_sale/receive',
            method: 'post',
            data
        })
    },
    // 新增退款(整单)
    addAfterSaleOrderApi: (data) => {
        return request({
            url: 'after_sale/order_add',
            method: 'post',
            data
        })
    },
    // 退款需要平台介入
    refundNeedDevApi: (data) => {
        return request({
            url: 'order/refund_need_dev',
            method: 'post',
            data
        })
    },
    // 撤销申请
    cancelAfterSaleApi: (data) => {
        return request({
            url: 'after_sale/cancel',
            method: 'post',
            data
        })
    },
    // 售后订单详情
    getAfterSaleDetailApi: (params) => {
        return request({
            url: 'after_sale/detail',
            method: 'get',
            params
        })
    },
    // 售后订单填写快递信息内容
    addExpressApi: (data) => {
        return request({
            url: 'after_sale/express',
            method: 'post',
            data
        })
    },
    // 获取订单状态
    getPayResultApi: (params) => {
        return request({
            url: 'order/pay_result',
            method: 'get',
            params
        })
    },
    // 获取订单状态
    getXxPayApi: (data) => {
        return request({
            url: 'order/update_offline_pay',
            method: 'post',
            data
        })
    },
}