import request from '@/utils/request';
export default {
    // 获取用户中心
    getUserCenterApi: () => {
        return request({
            url: '/user/center',
            method: 'get',
        })
    },
    // 获取消息列表
    getNoticeListApi: (params) => {
        return request({
            url: '/notice/index',
            method: 'get',
            params
        })
    },
    // 获取用户地址列表
    getAddressListApi: () => {
        return request({
            url: '/UserAddress/lists',
            method: 'get'
        })
    },
    // 添加用户地址列表
    addAddressApi: (data) => {
        return request({
            url: '/UserAddress/add',
            method: 'post',
            data
        })
    },
    // 设置用户默认地址
    setDefaultApi: (data) => {
        return request({
            url: '/UserAddress/setDefault',
            method: 'post',
            data
        })
    },
    // 获取用户一条地址详情
    getAddressDetailApi: (params) => {
        return request({
            url: '/UserAddress/detail',
            method: 'get',
            params
        })
    },
    // 更新用户收货地址
    updateAddressDetailApi: (data) => {
        return request({
            url: '/UserAddress/update',
            method: 'post',
            data
        })
    },
    // 删除用户收货地址
    delAddressDetailApi: (data) => {
        return request({
            url: '/UserAddress/del',
            method: 'post',
            data
        })
    },
    // 获取用户信息
    getUserInfoApi: () => {
        return request({
            url: 'user/info',
            method: 'get',
        })
    },
    // 修改用户信息
    setUserInfoApi: (data) => {
        return request({
            url: 'user/setInfo',
            method: 'post',
            data
        })
    },
    // 修改用户信息(批量)
    updateUserInfoApi: (data) => {
        return request({
            url: 'user/updateUser',
            method: 'post',
            data
        })
    },
    // 获取合同列表
    getContractListsApi: (params) => {
        return request({
            url: 'contract/getContractLists',
            method: 'get',
            params
        })
    },
    // 获取合同列表
    getSignedLinkApi: (params) => {
        return request({
            url: 'contract/getSignedLink',
            method: 'get',
            params
        })
    },
    // 查看合同
    getContractViewApi: (params) => {
        return request({
            url: 'contract/view',
            method: 'get',
            params
        })
    },
    // 添加子账号
    addSubAccountApi: (data) => {
        return request({
            url: 'user/sub_account',
            method: 'post',
            data
        })
    },
    // 获取发票订单列表
    getBillListsApi: (params) => {
        return request({
            url: 'bill/lists',
            method: 'get',
            params
        })
    },
    // 获取发票订单明细
    getBilSettlementRecordApi: (params) => {
        return request({
            url: 'bill/settlement_record',
            method: 'get',
            params
        })
    },
    // 获取发票订单详情
    getBillDetailApi: (params) => {
        return request({
            url: 'bill/bill_detail',
            method: 'get',
            params
        })
    },
    // 发票申请
    billApplyApi: (data) => {
        return request({
            url: 'bill/apply',
            method: 'post',
            data
        })
    },
    // 我的订单数据
    getStatusNumberApi: (data) => {
        return request({
            url: 'order/getStatusNumber',
            method: 'post',
            data
        })
    },
    // 子账号列表
    getAccountListApi: (data) => {
        return request({
            url: 'user/account_list',
            method: 'post',
            data
        })
    },
    // 获取消息列表
    getMessageListApi: (params) => {
        return request({
            url: 'message/lists',
            method: 'get',
            params
        })
    },
    // 获取消息详情
    getMessageDetailApi: (params) => {
        return request({
            url: 'message/detail',
            method: 'get',
            params
        })
    },
    // 子账号删除
    delSubAccountApi: (data) => {
        return request({
            url: 'user/sub_account_del',
            method: 'post',
            data
        })
    },
    // 子账号详情
    getSubAccountDetailApi: (params) => {
        return request({
            url: 'user/sub_account_detail',
            method: 'get',
            params
        })
    },
    // 子账号修改
    EditSubAccountDetailApi: (data) => {
        return request({
            url: 'user/sub_account_edit',
            method: 'post',
            data
        })
    },
    // 采购列表
    getPurchaseListApi: (params) => {
        return request({
            url: 'order/purchase_lists',
            method: 'get',
            params
        })
    },
    // 采购列表订单状态数量
    purchaseOrderNumberApi: (params) => {
        return request({
            url: 'order/purchaseOrderNumber',
            method: 'get',
            params
        })
    },
    // 余额消费列表
    getYueListApi: (params) => {
        return request({
            url: 'user/consumeRecord',
            method: 'get',
            params
        })
    },
    // 余额消费导出
    exportConsumeRecordApi: (params) => {
        return request({
            url: 'user/exportConsumeRecord',
            method: 'get',
            params
        })
    },
    // 汇款列表
    getRemitMoneyApi: (params) => {
        return request({
            url: 'settlement/remit_lists',
            method: 'get',
            params
        })
    },
    // 操作对账单
    getRmOperateApi: (data) => {
        return request({
            url: 'settlement/operate',
            method: 'post',
            data
        })
    },
    // 对账列表
    getSettlementListApi: (params) => {
        return request({
            url: 'settlement/lists',
            method: 'get',
            params
        })
    },
    // 对账详细
    getSettlementDetailApi: (params) => {
        return request({
            url: 'settlement/detail',
            method: 'get',
            params
        })
    },
    // 操作对账单
    setRmOperateApi: (data) => {
        return request({
            url: 'settlement/operate',
            method: 'post',
            data
        })
    },
    // 对账单相关的订单
    getSettleOrderApi: (params) => {
        return request({
            url: 'settlement/settle_order',
            method: 'get',
            params
        })
    },
    // 获取该集团下所有公司列表
    getCompanyGroupApi: (params) => {
        return request({
            url: 'user/getCompanyGroup',
            method: 'get',
            params
        })
    },
	// 获取审批配置列表
	getApprovalListsApi: (params) => {
		return request({
			url: 'approval/approvalLists',
			method: 'get',
			params
		})
	},
	// 获取审批类型列表
	getApprovalTypeListsApi: (params) => {
		return request({
			url: 'approval/approvalTypeLists',
			method: 'get',
			params
		})
	},
	// 获取会员列表
	getManagerListsApi: (params) => {
		return request({
			url: 'approval/managerLists',
			method: 'get',
			params
		})
	},
	// 新增审批流配置
	addApprovalApi: (data) => {
		return request({
			url: 'approval/addApproval',
			method: 'post',
			data
		})
	},
	// 编辑审批流配置
	editApprovalApi: (data) => {
		return request({
			url: 'approval/editApproval',
			method: 'post',
			data
		})
	},
	// 获取审批详情
	getApprovalDetailsApi: (params) => {
		return request({
			url: 'approval/approvalDetails',
			method: 'get',
			params
		})
	},
	// 获取申请对账审批列表
	getApprovalApplySettlementListsApi: (params) => {
		return request({
			url: 'ApplySettlement/approvalApplySettlementLists',
			method: 'get',
			params
		})
	},
	// 审批申请对账
	approvalApplySettlementApi: (data) => {
		return request({
			url: 'ApplySettlement/auditApplySettlement',
			method: 'post',
			data
		})
	},
	// 获取申请对账的订单列表
	getApplySettlementOrderListsApi: (params) => {
		return request({
			url: 'ApplySettlement/applySettlementOrderLists',
			method: 'get',
			params
		})
	},
	// 获取申请对账记录列表
	getApplySettlementRecordListsApi: (params) => {
		return request({
			url: 'ApplySettlement/applySettlementLogslists',
			method: 'get',
			params
		})
	},
	// 新增申请对账
	addApplySettlementApi: (data) => {
		return request({
			url: 'ApplySettlement/addApplySettlement',
			method: 'post',
			data
		})
	},
	// 获取可对账的订单列表
	getNoSettlementOrderListsApi: (params) => {
		return request({
			url: 'ApplySettlement/noSettlementOrderLists',
			method: 'get',
			params
		})
	},
	// 获取额度申请变更记录列表
	getApplyChangeStatusRecordListsApi: (params) => {
		return request({
			url: 'quota/applyChangeStatusLogsLists',
			method: 'get',
			params
		})
	},
	// 额度状态变更申请
	applyChangeStatusApi: (data) => {
		return request({
			url: 'quota/applyChangeStatus',
			method: 'post',
			data
		})
	},
	// 获取额度变更申请记录列表
	getApplyQuotaListsApi: (params) => {
		return request({
			url: 'quota/applyQuotaLists',
			method: 'get',
			params
		})
	},
	// 额度申请、调整
	applyQuotaApi: (data) => {
		return request({
			url: 'quota/applyQuota',
			method: 'post',
			data
		})
	},
	// 上传支付凭证
	uploadCertificateApi: (data) => {
		return request({
			url: 'quota/uploadCertificate',
			method: 'post',
			data
		})
	},
	// 获取预约链接
	getPreviewUrlApi: (params) => {
		return request({
			url: 'quota/preview',
			method: 'get',
			params
		})
	},
	// 获取签约链接
	getSignUrlApi: (params) => {
		return request({
			url: 'quota/sign',
			method: 'get',
			params
		})
	},

     // 获取我的商品集
     getMyGoodsListApi: () => {
        return request({
            url: '/User/getMyGoodsList',
            method: 'get'
        })
    },

      // 删除我的商品集
      delMyGoodsDetailApi: (data) => {
        return request({
            url: '/User/delMyGoodsDetail',
            method: 'post',
            data
        })
    },
    // 挑选商品存入商品集
    selectMyGoodsApi: (data) => {
        return request({
            url: '/User/selectMyGoods',
            method: 'post',
            data
        })
    },

     // 临时商品集
     getExistingListApi: (params) => {
        return request({
            url: 'User/existingList',
            method: 'get',
            params
        })
    },




    // 保存临时商品集
    addGoodsListApi: (params) => {
        return request({
            url: '/User/addGoodsList',
            method: 'get',
            params
        })
    },


     // 获取商品集制作PPT
     getGoodsListTopptApi: (params) => {
        return request({
            url: '/createPpt/getGoodsList',
            method: 'get',
            params
        })
    },


     // 获取商品集制作PDF
         getGoodsListTopdfApi: (params) => {
            return request({
                url: '/createPdf/getGoodsList',
                method: 'get',
                params
            })
        },

   // 删除用户临时商品集
    delTemporaryGoodsApi: (data) => {
        return request({
            url: '/User/delTemporaryGoods',
            method: 'post',
            data
        })
    },
      
	
}