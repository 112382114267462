export const UserRoute = [{
    path: "/user",
    name: "User",
    component: () => import("@/views/user/index"),
    children: [
        // 个人中心
        {
            path: '/center',
            name: "Center",
            component: () => import("@/views/user/center"),
            meta: {
                title: '个人中心',
                minor: true,
            },
        }, {
            path: '/yuelist',
            name: "yuelist",
            component: () => import("@/views/user/center/yuelist"),
            meta: {
                title: '额度消费明细',
                minor: true
            },
        }, {
            path: '/footprint',
            name: "Footprint",
            component: () => import("@/views/user/center/footprint"),
            meta: {
                title: '我的足迹',
                minor: true,
                hideSidebar: true
            },
        }, {
            path: '/editpwd',
            name: "EditPwd",
            component: () => import("@/views/user/center/edit_pwd"),
            meta: {
                title: '密码修改',
                minor: true,
            },
        }, {
            path: '/address',
            name: "Address",
            component: () => import("@/views/user/center/address"),
            meta: {
                title: '收货地址',
                minor: true,
            },
        }, {
            path: '/editaddress',
            name: "EditAddress",
            component: () => import("@/views/user/center/edit_address"),
            meta: {
                title: '编辑收货地址',
                minor: true,
            },
        },

        {
            path: '/addacount',
            name: "addAcount",
            component: () => import("@/views/user/center/add_acount"),
            meta: {
                title: '账号管理',
                minor: true,
            },
        }, {
            path: '/editcountlist',
            name: "acountEdit",
            component: () => import("@/views/user/center/add_acount"),
            meta: {
                title: '账号编辑',
                minor: true,
            },
        }, {
            path: '/viewcountlist',
            name: "acountView",
            component: () => import("@/views/user/center/add_acount"),
            meta: {
                title: '账号详情',
                minor: true,
            },
        }, {
            path: '/countlist',
            name: "count_list",
            component: () => import("@/views/user/center/count_list"),
            meta: {
                title: '账号管理',
                minor: true,
            },
        }, {
            path: '/message',
            name: "Message",
            component: () => import("@/views/user/center/message"),
            meta: {
                title: '消息中心',
                minor: true,
            },
        }, {
            path: '/messagedetail',
            name: "message_detail",
            component: () => import("@/views/user/center/message_detail"),
            meta: {
                title: '消息中心',
                minor: true,
            },
        },
        // 合同中心
        {
            path: '/certification',
            name: "Certification",
            component: () => import("@/views/user/contract/certification"),
            meta: {
                title: '认证管理',
                minor: true,
            },
        }, {
            path: '/certificationinfo',
            name: "CertificationInfo",
            component: () => import("@/views/user/contract/certificationinfo"),
            meta: {
                title: '认证管理',
                minor: true,
                elmenu: '/certification'
            },
        }, {
            path: '/contract',
            name: "Contract",
            component: () => import("@/views/user/contract"),
            meta: {
                title: '合同管理',
                minor: true,
            },
        },

        // 交易中心
        {
            path: '/favlist',
            name: "FavList",
            component: () => import("@/views/user/trading/fav_list"),
            meta: {
                title: '我的收藏',
                hideSidebar: true
            },
        }, {
            path: '/orderlist',
            name: "OrderList",
            component: () => import("@/views/user/trading/order_list"),
            meta: {
                title: '订单列表',
                minor: true,
            },
        }, {
            path: '/evaluatelist',
            name: "EvaluateList",
            component: () => import("@/views/user/trading/evaluate_list"),
            meta: {
                title: '订单评价',
                minor: true,
            },
        }, {
            path: '/orderdetail',
            name: "OrderDetail",
            component: () => import("@/views/user/trading/order_detail"),
            meta: {
                title: '订单详情',
                hideSidebar: true,
                minor: true,
            },
        }, {
            path: '/salesdetail',
            name: "SalesDetail",
            component: () => import("@/views/user/trading/sales_detail"),
            meta: {
                title: '售后详情',
                minor: true,
            },
        }, {
            path: '/saleslist',
            name: "SalesList",
            component: () => import("@/views/user/trading/sales_list"),
            meta: {
                title: '售后列表',
                minor: true,
            },
        }, {
            path: '/salesapply',
            name: "SalesApply",
            component: () => import("@/views/user/trading/sales_apply"),
            meta: {
                title: '申请售后',
                minor: true,
                elmenu: '/saleslist'
            },
        },

        // 采购中心
        {
            path: '/purchase',
            name: "Purchase",
            component: () => import("@/views/user/purchase"),
            meta: {
                title: '采购列表',
                minor: true,
            },
        },


        // 财务中心
        {
            path: '/financial',
            name: "Financial",
            component: () => import("@/views/user/financial"),
            meta: {
                title: '对账管理',
                minor: true,
            },
        },
        {
            path: '/financialDetail',
            name: "Detail",
            component: () => import("@/views/user/financial/financial_detail"),
            meta: {
                title: '对账明细',
                minor: true,
                elmenu: '/financial'
            },
        },

        // 发票中心
        {
            path: '/invoice',
            name: "Invoice",
            component: () => import("@/views/user/invoice/invoice_list"),
            meta: {
                title: '我的发票',
                minor: true,
            },
        }, {
            path: '/invoiceall',
            name: "InvoiceAll",
            component: () => import("@/views/user/invoice/invoice_all"),
            meta: {
                title: '发票明细',
                minor: true,
                elmenu: '/invoice'
            },
        }, {
            path: '/invoicedetail',
            name: "InvoiceDetail",
            component: () => import("@/views/user/invoice/invoice_detail"),
            meta: {
                title: '发票详情',
                minor: true,
                elmenu: '/invoice'
            },
        }, {
            path: '/invoiceapply',
            name: "InvoiceApply",
            component: () => import("@/views/user/invoice/invoice_apply"),
            meta: {
                title: '发票申请',
                minor: true,
                elmenu: '/invoice'
            },
        }, {
            path: '/remitmoney',
            name: "remitmoney",
            component: () => import("@/views/user/remitmoney"),
            meta: {
                title: '汇款管理',
                minor: true,
            },
        }, {
            path: '/remitmoneydetail',
            name: "remitmoneyDetail",
            component: () => import("@/views/user/remitmoney/remitmoney_detail"),
            meta: {
                title: '汇款管理详情',
                minor: true, 
                elmenu: '/remitmoney'
            },
        },

		// 审批中心
		{
		    path: '/approval',
		    name: "Approval",
		    component: () => import("@/views/user/approval/approval_list"),
		    meta: {
		        title: '审批管理',
		        minor: true,
		    },
		},{
		    path: '/addapproval',
		    name: "addApproval",
		    component: () => import("@/views/user/approval/add_approval"),
		    meta: {
		        title: '新增审批',
		        minor: true,
		    },
		},{
		    path: '/editapproval',
		    name: "editApproval",
		    component: () => import("@/views/user/approval/add_approval"),
		    meta: {
		        title: '修改审批',
		        minor: true,
		    },
		},
		// 申请对账审批
		{
		    path: '/applysettlement',
		    name: "applySettlement",
		    component: () => import("@/views/user/approval/apply_settlement_approval"),
		    meta: {
		        title: '申请对账审批列表',
		        minor: true,
		    },
		},{
		    path: '/applysettlementorderlists',
		    name: "applySettlementOrderLists",
		    component: () => import("@/views/user/approval/apply_settlement_order_lists"),
		    meta: {
		        title: '申请对账订单列表',
		        minor: true,
		    },
		},
		
		// 申请中心
		{
		    path: '/applysettlementrecord',
		    name: "applySettlementRecord",
		    component: () => import("@/views/user/apply/apply_settlement_record"),
		    meta: {
		        title: '对账申请记录',
		        minor: true,
		    },
		},{
		    path: '/addapplysettlement',
		    name: "addApplySettlement",
		    component: () => import("@/views/user/apply/add_apply_settlement"),
		    meta: {
		        title: '新增对账申请',
		        minor: true,
		    },
		},{
		    path: '/applystatusrecord',
		    name: "applyStatusRecord",
		    component: () => import("@/views/user/apply/apply_status_record"),
		    meta: {
		        title: '额度状态申请变更记录',
		        minor: true,
		    },
		},{
		    path: '/applychangerecord',
		    name: "applyChangeRecord",
		    component: () => import("@/views/user/apply/apply_change_record"),
		    meta: {
		        title: '额度申请变更记录',
		        minor: true,
		    },
		},
        
        //我的商品集
        {
		    path: '/mygoodslist',
		    name: "myGoodsList",
		    component: () => import("@/views/user/makeppt/mygoods_list"),
		    meta: {
		        title: '我的产品方案',
		        minor: true,
		    },
		},
        //制作商品集
        {
            path: '/selectmygoodslist',
            name: "selectMyGoodsList",
            component: () => import("@/views/user/makeppt/select_mygoods_list"),
            meta: {
                title: '制作产品方案',
                minor: true,
            },
        },
        //临时已存商品集
        {
            path: '/existinglist',
            name: "existingList",
            component: () => import("@/views/user/makeppt/existing_list"),
            meta: {
                title: '临时产品',
                minor: true,
            },
        },

          //保存商品集
        {
            path: '/savegoodslist',
            name: "saveGoodsList",
            component: () => import("@/views/user/makeppt/save_goods_list"),
            meta: {
                title: '临时已存商品集',
                minor: true,
            },
        },

        //编辑商品集
        {
            path: '/editgoodslist',
            name: "editGoodsList",
            component: () => import("@/views/user/makeppt/edit_goods_list"),
            meta: {
                title: '临时已存商品集',
                minor: true,
            },
        },


         //宝
         {
            path: '/editgoodslist',
            name: "editGoodsList",
            component: () => import("@/views/user/makeppt/edit_goods_list"),
            meta: {
                title: '临时已存商品集',
                minor: true,
            },
        },


        
        


    
    ]
}]