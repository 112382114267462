import request from '@/utils/request';
export default {
    // 登录
    loginApi: (data) => {
        return request({
            url: '/account/login',
            method: 'post',
            data
        })
    },
    //忘记密码
    forgetPasswordApi: (data) => {
        return request({
            url: 'login_password/forget',
            method: 'post',
            data
        })
    },
    //登出
    accountLogoutApi: () => {
        return request({
            url: 'account/logout',
            method: 'post'
        })
    },
    // 手机验证码登录
    sendSmsLoginApi: (data) => {
        return request({
            url: 'account/smsLogin',
            method: 'post',
            data
        })
    },
    // 注册
    sendRegisterApi: (data) => {
        return request({
            url: 'account/register',
            method: 'post',
            data
        })
    },
    // 发送短信验证码
    getSmsSendApi: (data) => {
        return request({
            url: 'sms/send',
            method: 'post',
            data
        })
    },
    // 获取图形验证码
    getCodeApi: () => {
        return request({
            url: 'code/code',
            method: 'get',
        })
    },
}